<template>
  <div class="AsomInputMultiEmail__Container">
    <div 
      :class="[ 'AsomInputMultiEmail', `AsomInputMultiEmail--state-${state}`]"
    >
      <div v-for="(email, i) in emails" :key="i" class="AsomInputMultiEmail__EmailTag">
        {{email}}
        <button @click="removeEmails(email, i)" class="AsomInputMultiEmail__EmailTagButton">
          <span class="sr-only">Close</span>
          <!-- Heroicon name: outline/x -->
          <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <input class="AsomInputMultiEmail__Input"
        v-model="inputData"
        @keyup.enter="onEnterKeyUp"
      />
    </div>
    <div class="AsomInputMultiEmail__Placeholder">
      <span>{{placeholder || 'Enter email and press Enter to add'}}</span>
    </div>
  </div>
</template>

<script>
import inputStateMixin from '../inputStateMixin'
import isEmail from "@/helpers/isEmail";

export default {
  name: "AsomInputMultiEmail",
  mixins: [ inputStateMixin ],
  props: {
    modelValue: {
      type: undefined,
    },
    disabled: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    }
  },
  emits: [ 'update:modelValue' ],
  data() {
    return {
      inputData: ""
    }
  },
  computed: {
    emails() {
      let result = [];
      if (this.modelValue)
        result = this.modelValue.split(";");
      return result;
    }
  },
  methods: {
    onEnterKeyUp() {
      if (isEmail(this.inputData)) {
        this.addEmail();
        this.inputData = "";
      }
    },
    addEmail() {
      const updatedEmails = [
        ...this.emails,
        this.inputData,
      ]
      this.updateModelValue(updatedEmails);
    },
    removeEmails(email, index) {
      const updatedEmails = this.emails.filter((_e, _i) => !(_e === email && _i === index));
      this.updateModelValue(updatedEmails);
    },
    updateModelValue(emails) {
      this.$emit('update:modelValue', emails.join(';'));
    }
  }
}
</script>
<style scoped>
.AsomInputMultiEmail__Container {
  @apply flex flex-col gap-1;
}

.AsomInputMultiEmail {
  @apply block px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border max-w-full w-full rounded-md shadow-sm;
}

.AsomInputMultiEmail--state-default {
  @apply border-gray-300 focus:border-blue-500 focus:ring-blue-500 focus:ring-2 focus:outline-none focus:ring-opacity-50 focus-within:border-blue-500 focus-within:ring-blue-500 focus-within:ring-2 focus-within:outline-none focus-within:ring-opacity-50 text-sm
}

.AsomInputMultiEmail--state-error {
  @apply border-red-300 ring-red-500 bg-red-50 focus:border-red-500 focus:ring-red-500 focus:ring-2 focus:outline-none focus:ring-opacity-50 focus-within:border-red-500 focus-within:ring-red-500 focus-within:ring-2 focus-within:outline-none focus-within:ring-opacity-50 text-sm
}

.AsomInputMultiEmail__Input {
  @apply border-none focus:outline-none bg-transparent w-full;
}

.AsomInputMultiEmail__EmailTag {
  @apply px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-lg bg-gray-100 text-gray-900 flex flex-row gap-1;
}

.AsomInputMultiEmail__Placeholder {
  @apply text-sm;
}
</style>