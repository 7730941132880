/**
 * Common properties shared by all input fields
 */
export default {
  props: {
    /**
     * Validation states
     * `default`, `error`
     */
    state: {
      type: String,
      default: 'default',
      validate: (value) => [ 'default', 'error' ].includes(value)
    },
  }
}