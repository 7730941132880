<template>
  <div class="pt-2">
    <slot :openScanner="openScanner">
      <asom-button
        outline
        size="sm"
        :text="text"
        icon="qr"
        @click="openScanner"
      />
    </slot>
    <teleport to="body">
      <div v-if="showScanner" :class="['AsomQrCodeScanner']">
        <div id="asoms-qr-scanner-container" class="viewport scanner">
          <button class="close" @click="closeScanner()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-12 w-12"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
          <video id="asoms-qr-scanner" ref="video"></video>
          <!-- <div id="asoms-qr-scanner" /> -->
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import QrScanner from 'qr-scanner';
import { Html5QrcodeSupportedFormats } from "html5-qrcode"
import scannerMixin from '../scanner.mixin';

export default {
  name: "AsomBarCodeScanner",
  props: {
    text: {
      type: String,
      default: "Scan QR Code",
    },
  },
  mixins: [ scannerMixin ],
  computed: {
    scannerId() {
      return "asoms-qr-scanner";
    },
    formatsToSupport() {
      return[
        Html5QrcodeSupportedFormats.QR_CODE,
        Html5QrcodeSupportedFormats.UPC_A,
        Html5QrcodeSupportedFormats.UPC_E,
        Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
      ]
    }
  },
  beforeUnmount() {
    if (this.scanner) {
      this.scanner.destroy();
      this.scanner = null;
    }
  },
  methods: {
    async closeScanner() {
      console.log(this.scanner);
      if (this.scanner) {
        this.scanner.stop();
        this.scanner.destroy();
        this.scanner = null;
      }
      await this.closeExistingCameraUsage();
      this.showScanner = false;
    },
    
    async closeExistingCameraUsage() {
      if (this.$refs.video) {
        let video = this.$refs.video;
        console.log("destroy video")
        video.setAttribute('autoplay', '');
        video.setAttribute('muted', '');
        video.setAttribute('playsinline', '');
      }
      if (navigator && navigator.mediaDevices) {
        const mediaStream = await navigator.mediaDevices.getUserMedia({audio: false, video: true});
        console.log(mediaStream);
        mediaStream.getTracks().forEach(track => track.stop());
      }
    },
    async createQrcodeScanner() {
      await this.closeExistingCameraUsage();
      const canUseCamera = await this.requestPermission();

      if (!canUseCamera) {
        alert(this.alertMessages_cannotUseCamera);
        return;
      }

      const { onScanSuccess } = this;
      
      const videoElem = this.$refs.video;
      if (!this.scanner) {
        this.scanner = new QrScanner(
          videoElem,
          onScanSuccess,
          {
            maxScansPerSecond: 2,
            highlightScanRegion: true,
          }
        );
      }
      this.scanner.start();
    },
    getScannerConfig() {
      const { minWidth, height, width } = this.getDeviceMediaSize();
      const config = { 
        fps: 10, 
        qrbox: { width: minWidth * 0.50, height: minWidth * 0.50 }, 
        aspectRatio: Math.ceil((height / width) * 100) / 100 
      }
      return config;
    },
    async onScanSuccess(decodedResult) {
      if (decodedResult && decodedResult.data !== this.lastResult) {
        this.lastResult = decodedResult.data;
        console.log(`Scan result ${decodedResult.data}`);
      }
      await this.closeScanner();
    }
  }
};
</script>
<style scoped>
.AsomQrCodeScanner {
  @apply w-screen h-screen fixed block top-0 left-0 bg-white;
  z-index: 1000;
}
.AsomQrCodeScanner--is-hidden {
  @apply hidden;
}

.close {
  z-index: 1;
  position: absolute;
  top: 15px;
  right: 15px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  border: none;
  outline: none;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

#asoms-qr-scanner-container {
  height: 100%;
}

#asoms-qr-scanner {
  height: 100%;
  width: 100% ;
  background-color: black;
  display: flex;
}
</style>
