<template>
  <vue-multiselect
    :class="['AsomMultiSelect', `AsomMultiSelect__state--${state}`]"
    :track-by="trackBy || 'value'"
    :label="label || 'label'"
    v-bind="$attrs"
    :options="options"
    :multiple="true"
    :searchable="true"
    v-model="computedValue"
    :disabled="disabled"
  />
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import inputStateMixin from "../inputStateMixin";

export default {
  name: "AsomMultiSelect",
  components: {
    VueMultiselect,
  },
  inheritAttrs: false,
  mixins: [inputStateMixin],
  props: {
    modelValue: {
      type: undefined
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    objectModelValue: {
      type: Boolean,
      default: true,
    },
    trackBy: {
      type: String,
    },
    label: {
      type: String,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    computedValue: {
      get() {
        if (this.objectModelValue) return this.modelValue;

        if (this.modelValue && this.options && this.options.filter) {
          return this.options
            .filter(option => this.modelValue.includes(option.value));
        }
        return null;
      },
      set(selectedOptions) {
        if (this.objectModelValue) {
          this.$emit("update:modelValue", selectedOptions);
          return;
        }
        this.$emit("update:modelValue", selectedOptions && selectedOptions.map(option => option.value));
      }
    }
  },
};
</script>