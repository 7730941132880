<template>
  <div class="AsomInputTextEditor">
    <quill-editor 
      theme="snow" 
      :contentType="contentType"
      v-model:content="editorData"
      :disabled="disabled"
      :options="editorOption"
      :readOnly="readOnly"
    />
  </div>
</template>
<script>
import inputStateMixin from '../inputStateMixin'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  name: "AsomInputTextEditor",
  data(){
    return {
      editorOption: {
        modules: {
          toolbar: [
            [{ size: [ 'huge', 'large', false , 'small']}],
            ["bold", "italic", "underline"],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }]
          ]
        }
      }
    }
  },
  components: {
    QuillEditor
  },
  inheritAttrs: false,
  mixins: [ inputStateMixin ],
  props: {
    'modelValue': {
      type: undefined
    },
    disabled: {
      type: Boolean
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    contentType: {
      type: String,
      default: 'html',
    }
  },
  emits: [ 'update:modelValue' ],
  computed: {
    editorData: {
      get() {
        return this.modelValue || '';
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  }
}
</script>
<style lang="scss">
.AsomInputTextEditor {
  @apply bg-white;
}
</style>