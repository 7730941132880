<template>
  <vue-multiselect
    :class="['AsomInputSelect', `AsomInputSelect__state--${state}`]"
    track-by="label"
    label="label"
    v-bind="$attrs"
    :options="options"
    v-model="computedValue"
    :searchable="true"
    :disabled="disabled"
    ref="multiselect"
    :placeholder="placeholder"
  >
  </vue-multiselect>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import inputStateMixin from "../inputStateMixin";

export default {
  name: "AsomInputSelect",
  components: {
    VueMultiselect,
  },
  inheritAttrs: false,
  mixins: [inputStateMixin],
  props: {
    modelValue: {
      type: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    objectModelValue: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Select option",
    },
  },
  emits: ["update:modelValue"],
  computed: {
    computedValue: {
      get() {
        if (this.objectModelValue) return this.modelValue;

        if (this.options && this.options.find) {
          return this.options.find((option) => option.value == this.modelValue);
        }
        return null;
      },
      set(selectedOption) {
        if (this.objectModelValue) {
          this.$emit("update:modelValue", selectedOption);
          return;
        }
        this.$emit("update:modelValue", selectedOption && selectedOption.value);
      },
    },
  },
  methods: {
    clearDropdownOptions() {
      if (this.$refs.multiselect && this.$refs.multiselect.refreshOptions)
        this.$refs.multiselect.refreshOptions();
    },
  },
};
</script>
