<template>
  <div class="AsomInputDateRange">
    <v-date-picker
      mode="range"
      is-range
      :update-on-input="false"
      :masks="masks"
      :min-date="minDate"
      :max-date="maxDate"
      :popover="popover"
      :modelValue="modelValue"
      @update:modelValue="(val) => $emit('update:modelValue', val)"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div class="flex justify-start items-center">
          <asom-input-text
            :state="state"
            :readonly="disabled"
            :disabled="disabled"
            :modelValue="inputValue.start"
            v-on="inputEvents.start"
          />
          <span class="mx-2 text-2xl font-bold">-</span>
          <asom-input-text
            :state="state"
            :modelValue="inputValue.end"
            v-on="inputEvents.end"
          />
        </div>
      </template>
    </v-date-picker>
  </div>
</template>
<script>
import AsomInputText from '../text/AsomInputText'
import inputStateMixin from '../inputStateMixin'

export default {
  name: 'AsomInputDateRange',
  components: {
    AsomInputText,
  },
  mixins: [ inputStateMixin ],
  props: {
    modelValue: {
      type: undefined
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    },
    datepickerPlacement: {
      type: String,
      default: 'right',
      validator: (val) => [
        'auto', 
        'top', 'top-start', 'top-end',
        'right', 'right-start', 'right-end',
        'bottom', 'bottom-start', 'bottom-end',
        'left', 'left-start', 'left-end', 
      ].includes(val)
    },
  },
  emits: [ 'update:modelValue' ],
  computed: {
    masks() {
      return {
        input: 'DD/MM/YYYY',
        inputDateTime24hr: 'DD/MM/YYYY HH:mm',
        inputTime24hr: 'HH:mm',
      }
    },
    popover() {
      return {
        visibility: this.disabled ? 'hidden' : 'click', 
        'placement': this.datepickerPlacement
      }
    }
  },
}
</script>