<template>
  <div 
    class="AsomInputTime"
  >
    <v-date-picker
      :readonly="disabled"
      :disabled="disabled" 
      mode="time" 
      :is24hr="true"
      :modelValue="modelValue"
      @update:modelValue="(val) => $emit('update:modelValue', val)"
    />
  </div>
</template>
<script>
import inputStateMixin from '../inputStateMixin'

export default {
  name: 'AsomInputTime',
  mixins: [ inputStateMixin ],
  props: {
    modelValue: {
      type: undefined
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    },
  },
  emits: [ 'update:modelValue' ],
}
</script>
<style lang="scss">
.AsomInputTime {
  .vc-time-date, .vc-am-pm, .vc-select-arrow {
    display: none !important;
  }

  .vc-select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 2px 30px 2px 2px;
    border: none;
  }
}
</style>