<template>
  <div class="sm:flex sm:py-3">
    <div
      class="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48"
    >
      <span class="flex items-center space-x-1">
        <asom-icon :icon="iconName" size="sm" />
        <span>{{ title }}</span>
      </span>
    </div>
    <div class="mt-1 text-sm text-gray-900 sm:mt-0 sm:ml-6 sm:col-span-2">
      {{ value }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
    },
    value: {
      type: String,
    },
  },
};
</script>
