<template>
  <div class="AsomSignatureView">
    <p v-if="error">Cannot load signature</p>
    <div class="w-full text-center" v-else-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <img v-else :src="imageSrc" alt="signature" class="w-full border-2 border-gray-200" />
  </div>
</template>

<script>
import { downloadFile } from "../../services/file.service";
export default {
  props: {
    fileId: {
      type: String,
    },
    offlineImageSrc: {
      type: String,
    },
  },
  data() {
    return {
      imageSrc: null,
      isLoading: false,
      error: false,
    };
  },
  mounted() {
    this.loadImage();
  },
  watch: {
    fileId() {
      this.loadImage();
    },
    offlineImageSrc() {
      this.loadImage();
    },
  },
  methods: {
    async loadImage() {
      if (this.offlineImageSrc) {
        this.imageSrc = this.offlineImageSrc;
      } else if (this.fileId) {
        this.error = false;
        this.isLoading = true;
        const resp = await downloadFile({
          fileId: this.fileId,
        });
        if (resp) {
          const blobUrl = URL.createObjectURL(resp.data);
          this.imageSrc = blobUrl;
        } else {
          this.error = true;
        }
        this.isLoading = false;
      }
    },
  },
};

</script>
<style scoped>
.AsomSignatureView {
  @apply pt-2;
  max-width: 300px;
}
</style>
