<template>
  <teleport to="body">
    <div
      v-if="visible"
      class="z-50 fixed inset-0 overflow-hidden md:py-8 h-full bg-gray-800 bg-opacity-50"
      @click="onOverlayClick"
    >
      <div class="w-full h-full b-red">
      </div>
      <div class="absolute inset-0 overflow-hidden">
        <transition name="slide">
          <section 
            v-if="innerVisible"
            class="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16"
            aria-labelledby="slide-over-heading"
          >
            <div class="w-screen max-w-lg">
              <div class="h-screen flex flex-col bg-white shadow-xl" @click="onMenuClick">
                <div class="px-4 py-6 bg-gray-100">
                  <div class="flex items-start justify-between">
                    <h2 id="slide-over-heading" class="text-lg font-medium text-gray-900">
                      {{ title }}
                    </h2>
                    <div class="ml-3 h-7 flex items-center">
                      <button @click="close" class="rounded-md text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500">
                        <span class="sr-only">Close panel</span>
                        <svg class="h-6 w-6" x-description="Heroicon name: outline/x" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="relative flex-1 px-4 overflow-y-auto">
                  <!-- Replace with your content -->
                  <div class="absolute inset-0 my-6 px-4">
                    <slot></slot>
                  </div>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </section>
        </transition>
      </div>
    </div>
  </teleport>
</template>

<script>
const OPEN_DELAY = 100
const CLOSE_DELAY = 500

export default {
  name: "AsomSlideMenu",
  props: {
    title: {
      type: String,
    },
    modelValue: {
      type: Boolean,
    },
    /**
     * @type Boolean
     * @description set to true to allow closing menu on overlay being clicked
     */
    overlayDismissible: {
      type: Boolean,
      default: true,
    }
  },
  emits: ["update:modelValue", "click-overlay"],
  data() {
    return {
      visible: false,
      innerVisible: false,
    };
  },
  mounted() {
    this.visible = this.modelValue;
    setTimeout(() => {
      this.innerVisible = this.modelValue
    }, OPEN_DELAY)
  },
  watch: {
    modelValue(newVal) {
      if (newVal) {
        this.visible = newVal;
        setTimeout(() => {
          this.innerVisible = newVal;
        }, OPEN_DELAY)
      } else {
        this.innerVisible = newVal;
        setTimeout(() => {
          this.visible = newVal;
        }, CLOSE_DELAY)
      }
    },
  },
  methods: {
    close() {
      this.innerVisible = false;
      this.$emit("update:modelValue", false);
      setTimeout(() => {
        this.visible = false;
      }, CLOSE_DELAY)
    },
    handleOverlayClicked() {
      this.close();
    },
    onMenuClick(event) {
      event.stopPropagation();
    },
    onOverlayClick() {
      if (this.overlayDismissible) {
        this.$emit('click-overlay');
        this.close();
      }
    }
  },
};
</script>

<style scoped>
.slide-enter-active {
  @apply transform transition ease-in-out duration-500 sm:duration-700;
}

.slide-leave-active {
  @apply transform transition ease-in-out duration-500 sm:duration-700;
}

.slide-enter-from {
  @apply translate-x-full;
}

.slide-enter-to {
  @apply translate-x-0;
}

.slide-leave-from {
  @apply translate-x-0;
}

.slide-leave-to {
  @apply translate-x-full;
}
</style>
