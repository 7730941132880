import get from "lodash.get"
import { displayUtcDate, displayUtcTime } from "@/helpers/dateTimeHelpers";
import { formatCurrency } from "@/helpers/numbers";

export default function(rowData, columns, searchableDateColumns = [], searchableTimeColumns = [], searchableCurrencyColumns = [], nonSearchableColumns = []) {
  function makeDataArray(data, columns) {
    let dataArray = [];
    const skipColumns = [...nonSearchableColumns, ...searchableDateColumns, ...searchableTimeColumns, ...searchableCurrencyColumns];
    columns && columns.filter( col  => !skipColumns.includes( col ))
      .forEach((col) => {
      dataArray.push( get(data, col, ""));
    });
    searchableDateColumns.forEach((col) => {
      dataArray.push( displayUtcDate( get(data, col, "")));
    });
    searchableTimeColumns.forEach((col) => {
      dataArray.push( displayUtcTime( get(data, col, "")));
    });
    searchableCurrencyColumns.forEach((col) => {
      dataArray.push( formatCurrency( get(data, col, "")));
    });
    return dataArray.flat();
  }
  return makeDataArray(rowData, columns).join(" ").toLowerCase();
}
