import { ref } from 'vue'
import get from 'lodash.get'
import debounce from 'lodash.debounce'

import createAxiosInstance from '@/services/createAxiosInstance'
import { errorHandler } from '@/services/serviceHandlers'

export default function() {
  const data = ref([])
  const isLoading = ref(false)
  const error = ref(null)
  const totalItems = ref(0)
  const skip = ref(0)
  const take = ref(100)
  const query = ref('')
  const queryKey = ref('search')
  const debounceDuration = ref(1000)

  function resetData() {
    data.value = []
    isLoading.value = false
    error.value = null
    skip.value = 0
    totalItems.value = 0
  }

  function setQuery(value) {
    query.value = value.trim()
  }

  function setQueryKey(value) {
    queryKey.value = value.trim()
  }

  function setDebounceDuration(value) {
    debounceDuration.value = value
  }

  function proceedDataDefault(asomListResponse) {
    data.value = [
      ...data.value,
      ...get(asomListResponse, 'data.list', []),
    ]
    totalItems.value = get(asomListResponse, 'data.filtered', data.value.length)
  }


  const loadData = debounce(async function({
    url,
    retrieveData = null,
    proceedData = null
  }) {
    if (isLoading.value)
      return;
    isLoading.value = true
    try {
      let response = {}
      if (retrieveData)
        response = await retrieveData({
          url: url,
          skip: skip.value,
          take: take.value,
          [queryKey.value]: query.value,
        })
      else
        response = await createAxiosInstance(true).get(url, {
          params: {
            skip: skip.value,
            take: take.value,
            [queryKey.value]: query.value,
          }
        })
      if (proceedData)
        proceedData(response)
      else
        proceedDataDefault(response)
      
      error.value = null
      skip.value = skip.value + take.value
    } catch (e) {
      console.log(e)
      const eResult = errorHandler(e)
      error.value = eResult.payload
    } finally {
      isLoading.value = false
    }
  }, debounceDuration.value);

  return {
    data,
    totalItems,
    isLoading,
    error,
    skip,
    take,
    resetData,
    loadData,
    query,
    setQuery,
    setQueryKey,
    debounceDuration,
    setDebounceDuration,
  }
}