export const ATTACHMENT_CATEGORIES = {
    OTHERS: 0,
    MANPOWER: 1,
    CASH_MANAGEMENT: 2,
    INVENTORY: 3,
    FAULT_MANAGEMENT: 4,
    NOTIFICATION: 5,
    OPS_COMM: 6,
    TOURIST: 7,
    SHIFT_HANDOVER: 8,
    SAP: 9,
}