import { ref } from 'vue'

export default function() {
  const currentPage = ref(1)

  function resetCurrentPage() {
    currentPage.value = 1
  }

  function setCurrentPage(pageNo) {
    currentPage.value = pageNo
  }

  return {
    currentPage,
    resetCurrentPage,
    setCurrentPage,
  }
}