<template>
  <div :class="[
    'AsomInputRadioGroup',
    horizontal && 'AsomInputRadioGroup--is-horizontal',
    disabled && 'AsomInputRadioGroup--is-disabled',
    `AsomInputRadioGroup--state-${state}`
  ]">
    <div v-for="(option, i) in options"
      :key="i"
      class="AsomInputRadioGroup__Option"
    >
      <div class="AsomInputRadioGroup__OptionInputWrapper">
        <input
          class="AsomInputRadioGroup__OptionInput"
          type="radio"
          :id="'AsomInputRadioGroup__OptionInput' + option.label"
          :value="option.value"
          v-model="selectedValue"
          :disabled="disabled"
          @change="onOptionChange"
        />
      </div>
      <div class="AsomInputRadioGroup__OptionLabelWrapper" v-if="option.label">
        <label v-if="option.label" :for="'AsomInputRadioGroup__OptionInput' + option.label" class="AsomInputRadioGroup__OptionLabel">{{option.label}}</label>
        <p v-if="option.description" class="AsomInputRadioGroup__OptionDescription">{{option.description}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import inputStateMixin from '../inputStateMixin'

export default {
  name: 'AsomInputRadioGroup',
  mixins: [ inputStateMixin ],
  props: {
    modelValue: undefined,
    disabled: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
      validate: (value) => value.every(item =>
          typeof item === 'object' &&
          (typeof item.value === 'string' || typeof item.value === 'number') &&
          typeof item.label === 'string' &&
          (!item.description || typeof item.description === 'string')
        )
    }
  },
  emits: [ 'update:modelValue' ],
  data() {
    return {
      selectedValue: ''
    }
  },
  mounted() {
    if (this.modelValue !== null)
      this.selectedValue = this.modelValue
  },
  watch: {
    modelValue(newVal) {
      if (this.selectedValue !== newVal)
        this.selectedValue = newVal || ''
    }
  },
  methods: {
    onOptionChange() {
      this.$nextTick(() => {
        this.$emit('update:modelValue', this.selectedValue)
      })
    }
  }
}
</script>
<style scoped>
.AsomInputRadioGroup {
  @apply space-y-4
}

.AsomInputRadioGroup--is-horizontal {
  @apply space-y-0 space-x-4 flex flex-row
}

.AsomInputRadioGroup__Option {
  @apply flex items-start
}

.AsomInputRadioGroup__OptionInputWrapper {
  @apply flex items-center h-5
}

.AsomInputRadioGroup__OptionInput {
  @apply h-4 w-4 border-gray-300 transition duration-200
}

.AsomInputRadioGroup__OptionLabelWrapper {
  @apply ml-3 text-sm
}

.AsomInputRadioGroup__OptionLabel {
  @apply text-gray-700
}

.AsomInputRadioGroup__OptionDescription {
  @apply text-gray-500
}

.AsomInputRadioGroup--state-default .AsomInputRadioGroup__OptionInput {
  @apply focus:ring-blue-500 text-blue-600
}

.AsomInputRadioGroup--state-error .AsomInputRadioGroup__OptionInput {
  @apply focus:ring-red-500 text-red-600
}

.AsomInputRadioGroup--state-error .AsomInputRadioGroup__OptionLabel {
  @apply text-red-600
}

.AsomInputRadioGroup--is-disabled .AsomInputRadioGroup__OptionInput {
  @apply cursor-not-allowed text-gray-400
}
</style>