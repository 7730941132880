<template>
  <nav :class="[ 'AsomPagination', disabled && 'AsomPagination--is-disabled' ]" aria-label="Pagination">
    <button
      v-if="showFirstButton"
      :class="[
        'AsomPagination__Button',
        'AsomPagination__NavigationButton',
        currentPage === 1 && 'AsomPagination__NavigationButton--disabled'
      ]"
      @click="onFirst"
    >
      <span class="sr-only">First</span>
      <asom-icon icon="chevron-double-left" size="sm" />
    </button>
    <button
      :class="[
        'AsomPagination__Button',
        'AsomPagination__NavigationButton',
        currentPage === 1 && 'AsomPagination__NavigationButton--disabled'
      ]"
      @click="onPrev"
    >
      <span class="sr-only">Previous</span>
      <asom-icon icon="chevron-left" />
    </button>
    <span
      v-if="currentPage > 3"
      class="AsomPagination__Space"
    >
      ...
    </span>
    <button class="AsomPagination__Button AsomPagination__PageButton" v-if="currentPage === totalPages && currentPage > 2" @click="onPageClick(currentPage - 2)">
      {{currentPage - 2}}
    </button>
    <button class="AsomPagination__Button AsomPagination__PageButton" v-if="currentPage > 1" @click="onPageClick(currentPage - 1)">
      {{currentPage - 1}}
    </button>
    <button class="AsomPagination__Button AsomPagination__PageButton AsomPagination__PageButton--current">
      {{currentPage}}
    </button>
    <button class="AsomPagination__Button AsomPagination__PageButton" v-if="currentPage < totalPages" @click="onPageClick(currentPage + 1)">
      {{currentPage + 1}}
    </button>
    <button class="AsomPagination__Button AsomPagination__PageButton" v-if="currentPage === 1 && currentPage < totalPages - 2" @click="onPageClick(currentPage + 2)">
      {{currentPage + 2}}
    </button>
    <span
      v-if="currentPage < totalPages - 1"
      class="AsomPagination__Space"
    >
      ...
    </span>
    <button
      :class="[
        'AsomPagination__Button',
        'AsomPagination__NavigationButton',
        currentPage === totalPages && 'AsomPagination__NavigationButton--disabled'
      ]"
      @click="onNext"
    >
      <span class="sr-only">Next</span>
      <asom-icon icon="chevron-right" />
    </button>
    <button
      v-if="showLastButton"
      :class="[
        'AsomPagination__Button',
        'AsomPagination__NavigationButton',
        currentPage === totalPages && 'AsomPagination__NavigationButton--disabled'
      ]"
      @click="onLast"
    >
      <span class="sr-only">Last</span>
      <asom-icon icon="chevron-double-right" size="sm" />
    </button>
  </nav>
</template>
<script>
import AsomIcon from '@/components/icon/AsomIcon'

const DEFAULT_ITEM_PER_PAGE = 10
export default {
  name: 'AsomPagination',
  components: { AsomIcon },
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: [String, Number],
      default: DEFAULT_ITEM_PER_PAGE,
    },
    totalItems: {
      type: [String, Number],
      default: 0
    },
    showFirstButton: {
      type: Boolean,
      default: true,
    },
    showLastButton: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  emits: [ 'update:modelValue' ],
  data() {
    return {
      currentPage: 1,
    }
  },
  mounted() {
    if (this.modelValue)
      this.currentPage = this.modelValue
  },
  computed: {
    itemsPerPageInt() {
      return this.itemsPerPage &&
        Number(this.itemsPerPage) &&
        Number.isInteger(Number(this.itemsPerPage)) &&
        Number(this.itemsPerPage) > 0
        ? Number(this.itemsPerPage)
        : 10
    },
    totalItemsInt() {
      return this.totalItems &&
        Number(this.totalItems) &&
        Number.isInteger(Number(this.totalItems)) &&
        Number(this.totalItems) > 0
        ? Number(this.totalItems)
        : 0
    },
    startIndex() {
      return this.totalItemsInt === 0 ? 0 : (this.currentPage - 1) * this.itemsPerPageInt + 1
    },
    lastIndex() {
      return this.totalItemsInt === 0
        ? 0
        : Math.min(this.currentPage * this.itemsPerPageInt, this.totalItemsInt)
    },
    totalPages() {
      return this.totalItemsInt === 0 ? 1 : Math.ceil(this.totalItemsInt / this.itemsPerPageInt)
    },
  },
  watch: {
    totalPages() {
      this.onPageClick(1)
    },
    modelValue() {
      this.currentPage = this.modelValue
    }
  },
  methods: {
    onPageClick(pageNumber) {
      if (this.currentPage !== pageNumber) {
        this.currentPage = pageNumber
        this.$emit("update:modelValue", this.currentPage)
      }
    },

    onPrev() {
      if (this.currentPage > 1) {
        this.currentPage--
        this.$emit("update:modelValue", this.currentPage)
      }
    },

    onNext() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++
        this.$emit("update:modelValue", this.currentPage)
      }
    },

    onFirst() {
      if (this.currentPage > 1) {
        this.currentPage = 1
        this.$emit("update:modelValue", this.currentPage)
      }
    },

    onLast() {
      if (this.currentPage < this.totalPages) {
        this.currentPage = this.totalPages
        this.$emit("update:modelValue", this.currentPage)
      }
    },

  },
}
</script>

<style>
.AsomPagination {
  @apply relative z-0 inline-flex shadow-sm -space-x-px
}

.AsomPagination__Button {
  @apply relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50
}

.AsomPagination__Space {
  @apply  relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium
}

.AsomPagination__Button:first-child {
  @apply rounded-l-md px-2 text-gray-500
}

.AsomPagination__Button:last-child {
  @apply rounded-r-md px-2 text-gray-500
}

.AsomPagination__NavigationButton {
 @apply px-2 py-2 text-gray-500 hover:bg-gray-50
}

.AsomPagination--is-disabled .AsomPagination__Button,
.AsomPagination--is-disabled .AsomPagination__Space,
.AsomPagination__NavigationButton--disabled {
  @apply bg-gray-200 hover:bg-gray-200 cursor-not-allowed
}

.AsomPagination__PageButton {
  @apply px-4 py-2 text-gray-700 hover:bg-gray-50
}

.AsomPagination__PageButton--current {
  @apply bg-blue-200 hover:bg-blue-300 font-bold
}
</style>