import { reactive, toRefs } from 'vue'


export default function() {
  const sortedData = reactive({
    column: null,
    ascending: true
  })

  const { column, ascending } = toRefs(sortedData)

  const resetSort = () => {
    column.value = null
    ascending.value = true
  }

  const toggleSortingColumn = (columnName) => {
    if (column.value !== columnName) {
      column.value = columnName
      ascending.value = true
    } else {
      ascending.value = !ascending.value
    }
  }

  return {
    sortedData,
    resetSort,
    toggleSortingColumn,
  }
}