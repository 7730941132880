import { ref } from 'vue'

export default function() {
  const innerItemsPerPage = ref(10)

  function setInnerItemsPerPage(val) {
    innerItemsPerPage.value = val
  }

  return {
    innerItemsPerPage,
    setInnerItemsPerPage,
  }
}