<template>
  <div
    :class="[
      'AsomInputCheckbox',
      `AsomInputCheckbox--state-${state}`,
      disabled && 'AsomInputCheckbox--is-disabled',
    ]"
  >
    <div class="AsomInputCheckbox__CheckboxWrapper">
      <input
        :id="id"
        ref="input"
        type="checkbox"
        class="AsomInputCheckbox__Checkbox"
        v-model="checkboxValue"
        :disabled="disabled"
        @change="onChange"
      />
    </div>
    <div class="AsomInputCheckbox__LabelWrapper" v-if="label">
      <label v-if="label" :for="name" class="AsomInputCheckbox__Label">{{
        label
      }}</label>
      <p v-if="description" class="AsomInputCheckbox__Description">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
import inputStateMixin from "../inputStateMixin";
import inputIdMixin from "../inputIdMixin";

export default {
  name: "AsomInputCheckbox",
  mixins: [inputStateMixin, inputIdMixin],
  props: {
    value: {
      type: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
    },
    description: {
      type: String,
    },
    modelValue: {
      type: Boolean,
    }
  },
  emits: ["update:modelValue"],
  computed: {
    checkboxValue: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  }
};
</script>
<style>
.AsomInputCheckbox {
  @apply flex items-start;
}

.AsomInputCheckbox__CheckboxWrapper {
  @apply flex items-center h-5;
}

.AsomInputCheckbox__Checkbox {
  @apply h-4 w-4 border-gray-300 rounded;
}

.AsomInputCheckbox__LabelWrapper {
  @apply ml-3 text-sm;
}

.AsomInputCheckbox__Label {
  @apply font-bold text-gray-700;
}

.AsomInputCheckbox__Description {
  @apply text-gray-500;
}

.AsomInputCheckbox--state-default .AsomInputCheckbox__Checkbox {
  @apply focus:ring-blue-500 text-blue-600;
}

.AsomInputCheckbox--state-error .AsomInputCheckbox__Checkbox {
  @apply focus:ring-red-500 text-red-600;
}

.AsomInputCheckbox--state-error .AsomInputCheckbox__Label {
  @apply text-red-600;
}

.AsomInputCheckbox--is-disabled .AsomInputCheckbox__Checkbox {
  @apply cursor-not-allowed text-gray-400;
}
</style>
