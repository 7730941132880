<template>
  <div class="AsomTable__Container">
    <div
      v-if="filterable || pagination"
      class="AsomTable__FilterAndSelectContainer"
    >
      <div class="AsomTable__FilterContainer">
        <asom-input-text
          v-if="filterable"
          :placeholder="filterPlaceholder"
          :modelValue="query"
          @update:modelValue="setQuery"
        />
      </div>
      <div class="AsomTable__ItemsPerPageSelectContainer">
        <p class="AsomTable__Label">{{paginationSelectLabel}}</p>
        <asom-input-select
          class="AsomTable__ItemsPerPageSelect"
          v-if="pagination"
          :objectModelValue="false"
          v-model="computedInnersItemPerPage"
          :options="paginationSelectableOptions"
          placeholder=""
          tagPlaceholder=""
          selectLabel=""
          selectedLabel=""
          deselectLabel=""
        />
      </div>
    </div>
    <div class="AsomTable__TableContainer">
      <table :class="[
          'AsomTable',
          striped && 'AsomTable--striped',
          tableClasses,
        ]"
      >
        <caption class="hidden"></caption>
        <thead>
          <tr>
            <th v-for="(c, i) in columns"
              :key="i"
              scope="col"
              :class="getThClasses(c)"
            >
              <div>
                <slot :name="`header_${c}`" :column="c">
                  {{columnText(c)}}
                </slot>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(r, i) in shownData" :key="i">
            <td v-for="(c, i2) in columns"
              :asoms-table-label="columnText(c)"
              :key="`r_${i}_c_${i2}`"
              :class="getTdClasses(c, r, i)"
            >
              <slot :name="c" :column="c" :data="cellText(c, r)" :rowIndex="firstShownItemIndex + i" :rowData="r">
                {{cellText(c, r)}}
              </slot>
            </td>
          </tr>
        </tbody>
        <slot name="tfoot"></slot>
      </table>
    </div>
    <div class="w-full text-center my-4" v-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <div
      v-if="pagination"
      class="AsomTable__PaginationContainer"
    >
      <div>
        <p class="AsomTable__Label">
          Showing
          <span class="AsomTable__Label--highlighted">{{totalItems ? firstShownItemIndex + 1 : 0}}</span>
          to
          <span class="AsomTable__Label--highlighted">{{lastShownItemIndex + 1}}</span>
          of
          <span class="AsomTable__Label--highlighted">{{totalItems}}</span>
          results
        </p>
      </div>
      <asom-pagination
        :show-first-button="false"
        :show-last-button="false"
        :disabled="isLoading"
        v-if="totalItems > innerItemsPerPage"
        :total-items="totalItems"
        :items-per-page="innerItemsPerPage"
        :modelValue="currentPage"
        @update:modelValue="setCurrentPage"
      />
    </div>
  </div>
</template>
<script>
import { toRefs, watch, onMounted } from 'vue'
import usePagination from './compositions/usePagination'
import useItemsPerPage from './compositions/useItemsPerPage'
import useServerData from './compositions/useServerData'
import tableMixins from './tableMixins'

import AsomPagination from '../pagination/AsomPagination'
import AsomInputSelect from '../input/select/AsomInputSelect'
import AsomInputText from '../input/text/AsomInputText'
import AsomIcon from '../icon/AsomIcon'

export default {
  name: 'AsomServerTable',
  components: {
    AsomPagination,
    AsomInputSelect,
    AsomInputText,
    AsomIcon,
  },
  mixins: [ tableMixins ],
  props: {
    url: {
      type: String,
      required: true,
    },
    retrieveData: {
      type: Function,
    },
    proceedData: {
      type: Function,
    },
  },
  setup(props) {
    const { url, itemsPerPage, columns } = toRefs(props)

    const {
      data,
      skip,
      query,
      setQuery,
      totalItems,
      resetData,
      loadData,
      isLoading,
    } = useServerData()
    const { currentPage, setCurrentPage, resetCurrentPage, } = usePagination()
    const { innerItemsPerPage, setInnerItemsPerPage } = useItemsPerPage()

    function loadNext(_currentPage, _innerItemsPerPage, dataCount) {
      if (_currentPage * _innerItemsPerPage > dataCount) {
        loadData({
          url: url.value
        })
      } 
    }

    watch(skip, (newValue) => newValue === 0 && resetCurrentPage())
    watch(columns, resetCurrentPage)
    watch(innerItemsPerPage, resetCurrentPage)
    watch(innerItemsPerPage, (newVal) => loadNext(currentPage.value, newVal, data.value.length))
    watch(currentPage, (newVal) => loadNext(newVal, innerItemsPerPage.value, data.value.length))
    watch(query, resetCurrentPage)
    watch(query, resetData)
    watch(query, function() {
      loadData({
        url: url.value,
      })
    })
    watch(itemsPerPage, (oldVal, newVal) => setInnerItemsPerPage(newVal))

    onMounted(() => {
      setInnerItemsPerPage(itemsPerPage.value)
      loadData({
        url: url.value
      })
    })

    return {
      currentPage,
      setCurrentPage,
      resetCurrentPage,
      innerItemsPerPage,
      setInnerItemsPerPage,
      data,
      totalItems,
      resetData,
      loadData,
      query,
      setQuery,
      isLoading,
    }
  },
  computed: {
    firstShownItemIndex() {
      if (this.pagination)
        return (this.currentPage - 1) * this.innerItemsPerPage 
      return 1
    },
    lastShownItemIndex() {
      if (this.pagination)
        return this.currentPage * this.innerItemsPerPage < this.totalItems ? this.currentPage * this.innerItemsPerPage - 1 : this.totalItems - 1
      return this.totalItems
    },
    shownData() {
      let shownData = this.data
      return shownData.slice(this.firstShownItemIndex, this.lastShownItemIndex + 1)
    },
    computedQuery: {
      get() {
        return this.query
      },
      set(newValue) {
        this.query = newValue
      }
    }
  },
}
</script>
<style src="./table.css">
</style>
<style scoped>
.AsomTable__ItemsPerPageSelect {
  width: 100px;
}
</style>