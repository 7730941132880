<template>
  <div
    :class="[
      'AsomInputCheckboxGroup',
      horizontal && 'AsomInputCheckboxGroup--is-horizontal',
    ]"
  >
    <asom-input-checkbox
      v-for="(option, i) in options"
      :key="i"
      :name="id + option.value"
      :id="id + option.value"
      :value="option.value"
      :modelValue="modelValue.includes(option.value)"
      @update:modelValue="onCheckboxChange(option)"
      :label="option.label"
      :description="option.description"
      :state="state"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import inputStateMixin from "../inputStateMixin";
import inputIdMixin from "../inputIdMixin";
import AsomInputCheckbox from "./AsomInputCheckbox";

export default {
  name: "AsomInputCheckboxGroup",
  components: {
    AsomInputCheckbox,
  },
  mixins: [inputStateMixin, inputIdMixin],
  props: {
    modelValue: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
      validate: (value) =>
        value.every(
          (item) =>
            typeof item === "object" &&
            (typeof item.value === "string" ||
              typeof item.value === "number") &&
            typeof item.label === "string" &&
            (!item.description || typeof item.description === "string")
        ),
    },
  },
  emits: ["update:modelValue"],
  methods: {
    onCheckboxChange({ value }) {
      let newModelValue = this.options.map((o) => o.value);
      if (this.modelValue.includes(value)) {
        newModelValue = newModelValue
          .filter((v) => this.modelValue.includes(v))
          .filter((v) => v !== value);
      } else {
        newModelValue = newModelValue.filter(
          (v) => this.modelValue.includes(v) || v === value
        );
      }
      this.$emit("update:modelValue", newModelValue);
    },
  },
};
</script>
<style scoped>
.AsomInputCheckboxGroup {
  @apply space-y-4 mt-4;
}

.AsomInputCheckboxGroup--is-horizontal {
  @apply space-y-0 space-x-4 flex flex-row;
}
</style>
