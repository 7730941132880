<template>
  <div class="AsomHumanDiagramView">
    <p v-if="error">Cannot load diagram</p>
    <div class="w-full text-center" v-else-if="isLoading">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <img v-else :src="imageSrc" alt="diagram" class="w-full border-2 border-gray-200" />
  </div>
</template>
<script>
import { downloadFile } from '@/services/file.service';
export default {
  props: {
    fileId: {
      type: String,
    }
  },
  data() {
    return {
      imageSrc: null,
      isLoading: false,
      error: false,
    }
  },
  mounted() {
    this.loadImage(this.fileId);
  },
  watch: {
    fileId(newValue) {
      this.loadImage(newValue);
    }
  },
  methods: {
    async loadImage(_fileId) {
      if (typeof _fileId !== 'string') return;
      this.error = false;
      this.isLoading = true;
      const resp = await downloadFile({
        fileId: _fileId,
      });
      if (resp) {
        const blobUrl = URL.createObjectURL(resp.data);
        this.imageSrc = blobUrl;
      } else {
        this.error = true;
      }
      this.isLoading = false;
    },
  }
}
</script>
<style scoped>
.AsomHumanDiagramView {
  @apply pt-2;
  max-width: 480px;
}
</style>