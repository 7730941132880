<template>
  <a class="AsomLink" v-bind="$attrs">
    <slot></slot>
  </a>
</template>
<script>
export default {
  name: 'AsomLink',
}
</script>
<style scoped>
.AsomLink {
  @apply text-blue-500 hover:text-blue-600 visited:text-purple-600 cursor-pointer select-none
}

.AsomLink, .AsomLink:visited, .AsomLink:hover, .AsomLink:active {
  /* color: inherit; */
}
</style>