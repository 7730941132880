<template>
  <div class="AsomSignaturePad">
    <div class="AsomSignaturePad__CanvasContainer">
      <canvas class="AsomSignaturePad__Canvas" :id="canvasId" ref="canvas" :width="width" :height="height">
      </canvas>
    </div>
    <asom-link @click="clear()">Clear Signature</asom-link>
  </div>
</template>

<script>
import { nanoid } from 'nanoid';
import signaturePad from 'signature_pad'
import AsomLink from '../link/AsomLink'

export default {
  name: 'AsomSignaturePad',
  components: {
    AsomLink,
  },
  props: {
    width: {
      type: Number,
      default: 500,
    },
    height: {
      type: Number,
      default: 250
    },
    /**
     * Format for modelValue
     * Support PNG, JPEG, SVG and Array of point group
     */
    format: {
      type: String,
      default: 'png',
      validator: (val) => [ 'array', 'png', 'jpeg', 'svg' ].includes(val)
    },
    /**
     * Return data URI of image in selected format
     */
    modelValue: {
      type: undefined,
    }
  },
  emits: [ 'update:modelValue', 'clear' ],
  data() {
    return {
      signature: null,
      canvasId: 'asomSignaturePad__Canvas__' + nanoid(),
      canvasOptions: {
        backgroundColor: 'rgb(255,255,255)',
        penColor: 'black',
      }
    }
  },
  mounted() {
    const canvas = this.$refs.canvas;
    this.signaturePad = new signaturePad(canvas, {
      ...this.canvasOptions,
      onEnd: this.onCanvasUpdate,
    });
  },
  beforeUnmount() {
    if (this.signaturePad) {
      this.signaturePad.off();
    }
  },
  watch: {
    format: function(newFormat) {
      if (newFormat)
        this.onCanvasUpdate();
    }
  },
  methods: {
    /**
     * TODO: apply to main logic if needed
     * resize logic based on lib suggestion: https://www.npmjs.com/package/signature_pad#handling-high-dpi-screens
     */
    resizeCanvas() {
      if (!this.signaturePad)
        throw new Error('Cannot find signature pad');
      const canvas = this.$refs.canvas;
      const data = this.signaturePad.toData();
      const ratio =  Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
      this.clear(); // otherwise isEmpty() might return incorrect value
      this.signaturePad.fromData(data);
      this.onCanvasUpdate();
    },
    clear() {
      if (this.signaturePad) {
        this.signaturePad.clear();
        this.$emit('update:modelValue', null);
      } else {
        throw new Error('Cannot find signature pad');
      }
    },
    onCanvasUpdate() {
      if (this.signaturePad) {
        if (this.signaturePad.isEmpty())
          return;
        switch (this.format) {
          case 'png':
            this.$emit('update:modelValue', this.signaturePad.toDataURL());
            break;
          case 'jpeg':
            this.$emit('update:modelValue', this.signaturePad.toDataURL("image/jpeg"));
            break;
          case 'svg':
            this.$emit('update:modelValue', this.signaturePad.toDataURL("image/svg+xml"));
            break;
          default:
            this.$emit('update:modelValue', this.signaturePad.toData());
            break;
        }
      } else {
        throw new Error('Cannot find signature pad');
      }
    },
  }
}
</script>
<style scoped>
.AsomSignaturePad {
  @apply space-y-2 text-left
}

.AsomSignaturePad__Canvas {
  @apply border-2 border-gray-400
}
</style>