import cloneDeep from "lodash.clonedeep";

export default {
  props: {
    filter: {
      type: Function,
      default: null,
    },
    columns: {
      type: Array,
      required: true,
    },
    striped: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    sortableColumns: {
      type: Array,
      default: () => [],
    },
    searchableDateColumns: {
      type: Array,
      default: () => [],
    },
    searchableTimeColumns: {
      type: Array,
      default: () => [],
    },
    searchableCurrencyColumns: {
      type: Array,
      default: () => [],
    },
    nonSearchableColumns: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: [Number, String],
      default: 10,
    },
    paginationOptions: {
      type: Array,
      default: [5, 10, 20, 50, 100],
    },
    filterPlaceholder: {
      type: String,
      default: "Type to search",
    },
    paginationSelectLabel: {
      type: String,
      default: "Rows per page: ",
    },
    /**
     * set of classes for <table> element
     */
    tableClasses: {
      type: String,
      default: "",
    },
    /**
     * set of classes for <th> element in table
     * each ruleset can be a string or an array
     * e.g. {
     *  "column_1": "bg-red-100 text-bold",
     *  "column_2": [
     *    "bg-blue-100",
     *    "text-bold",
     *  ],
     * }
     */
    thClasses: {
      type: Object,
      default: () => {},
      validator: (ruleset) =>
        ruleset &&
        Object.values(ruleset).every(
          (classes) =>
            typeof classes === "string" ||
            (Array.isArray(classes) &&
              classes.every((c) => typeof c === "string"))
        ),
    },
    /**
     * set of class and rule for applying class based on cell data and row index for <td> element
     * each ruleset must be a function returning boolean value
     * e.g. {
     *  "column_1": {
     *    "class_1": () => true, // apply regardless of data
     *    "class_2": (row) => row.dataX == '123', // apply under certain condition.
     *    "class_3": (row, rowIndex) => rowIndex%2 === 1 // apply for every odd row
     *  },
     * }
     */
    tdClasses: {
      type: Object,
      default: () => {},
      validator: (ruleset) =>
        ruleset &&
        Object.values(ruleset).every(
          (cellRules) =>
            cellRules &&
            Object.values(cellRules).every((rule) => typeof rule === "function")
        ),
    },
  },
  computed: {
    paginationSelectableOptions() {
      return this.paginationOptions.map((o) => ({
        label: o.toString(),
        value: o,
      }));
    },
    computedInnersItemPerPage: {
      get() {
        return this.innerItemsPerPage;
      },
      set(selectedOptions) {
        this.setInnerItemsPerPage(selectedOptions);
      },
    },
  },
  methods: {
    formatColumnName(columnName) {
      return columnName
        .replace(/([a-z0-9])([A-Z])/g, "$1 $2") // for camel case
        .split("-")
        .join("_")
        .split("_")
        .filter((c) => !!c)
        .map((c) => c.charAt(0).toUpperCase() + c.slice(1))
        .join(" ");
    },
    columnKey(column) {
      if (typeof column === "string") return column;
      else return null;
    },
    columnText(column) {
      if (typeof column === "string") return this.formatColumnName(column);
      else return "";
    },
    cellText(column, row) {
      try {
        const columnKey = this.columnKey(column);
        if (columnKey) {
          return row[columnKey];
        }
      } catch (e) {
        return "";
      }
    },
    getThClasses(column) {
      if (!this.thClasses || !this.thClasses[column]) return [];
      return this.thClasses[column];
    },
    getTdClasses(column, rowData, rowIndex) {
      if (!this.tdClasses || !this.tdClasses[column]) return [];
      const classList = Object.keys(this.tdClasses[column]).filter(
        (className) => {
          try {
            const cloneData = cloneDeep(rowData); // clone to avoid mistakenly data modification
            const canApply = this.tdClasses[column][className](
              cloneData,
              rowIndex
            );
            return typeof canApply === "boolean" ? canApply : false;
          } catch (e) {
            console.log(e);
            return false;
          }
        }
      );
      return classList;
    },
  },
};
