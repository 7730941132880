export const InventoryGroups = {
  GENERAL: {
    NAME: "General",
    VALUE: 1,
  },
  SMART_CARD: {
    NAME: "Smart Card",
    VALUE: 2,
  },
  SERIALISED_TAG: {
    NAME: "Tracked Items",
    VALUE: 3,
  },
  FIRST_AID: {
    NAME: "First Aid",
    VALUE: 4,
  },
};

export const CompareResult = {
  NIL: {
    NAME: "Nil",
    VALUE: 0,
  },
  ALIGN: {
    NAME: "Align",
    VALUE: 1,
  },
  DISCREPANT: {
    NAME: "Discrepant",
    VALUE: 2,
  },
};

export const OrderStatus = {
  IN_REVIEW: {
    NAME: "In Review",
    VALUE: 1,
  },
  REJECTED: {
    NAME: "Rejected",
    VALUE: 2,
  },
  PENDING_DELIVERY: {
    NAME: "Pending Delivery",
    VALUE: 3,
  },
  DELIVERY_FULFILLED: {
    NAME: "Delivery Fulfilled",
    VALUE: 4,
  },
};

export const InventoryTransactionTypes = {
  MOVE_IN: {
    NAME: "Move In",
    VALUE: 1,
  },
  MOVE_OUT: {
    NAME: "Move Out",
    VALUE: 2,
  },
  USE: {
    NAME: "Use",
    VALUE: 3,
  },
  OPEN_SMART_CARD_BOX: {
    NAME: "Open Smart Card Box",
    VALUE: 4,
  },
  VERIFY_FIRST_AID: {
    NAME: "Verify First Aid",
    VALUE: 5,
  },
  UPDATE: {
    NAME: "Update",
    VALUE: 6,
  },
  FIRST_AID_LOGBOOK: {
    NAME: "First aid logbook",
    VALUE: 7,
  },
};
