<template>
  <div class="AsomInputDate">
    <v-date-picker
      mode="date"
      :popover="popover"
      :modelValue="modelValue"
      :min-date="minDate"
      :max-date="maxDate"
      @update:modelValue="(val) => $emit('update:modelValue', val)"
      :masks="masks"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <asom-input-text
          :state="state"
          :readonly=true
          :disabled="disabled"
          :modelValue="inputValue"
          v-on="inputEvents"
        />
      </template>
    </v-date-picker>
  </div>
</template>
<script>
import AsomInputText from '../text/AsomInputText'
import inputStateMixin from '../inputStateMixin'

export default {
  name: 'AsomInputDate',
  components: {
    AsomInputText,
  },
  mixins: [ inputStateMixin ],
  props: {
    modelValue: {
      type: undefined
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    },
    datepickerPlacement: {
      type: String,
      default: 'bottom',
      validator: (val) => [
        'auto', 
        'top', 'top-start', 'top-end',
        'right', 'right-start', 'right-end',
        'bottom', 'bottom-start', 'bottom-end',
        'left', 'left-start', 'left-end', 
      ].includes(val)
    },
  },
  emits: [ 'update:modelValue' ],
  computed: {
    masks() {
      return {
        input: 'DD/MM/YYYY',
        inputDateTime24hr: 'DD/MM/YYYY HH:mm',
        inputTime24hr: 'HH:mm',
      }
    },
    popover() {
      return {
        visibility: this.disabled ? 'hidden' : 'click', 
        'placement': this.datepickerPlacement
      }
    }
  },
}
</script>
<style lang="scss">
  // Fix incomplete display of date control selection date panel in safari 14
  .AsomInputDate {
    position: relative;
  }
</style>