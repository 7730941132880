<template>
  <textarea
    :rows="rows"
    :class="[
      'AsomInputTextarea',
      `AsomInputTextarea--state-${state}`,
      disabled && 'AsomInputTextarea--is-disabled'
    ]"
    v-bind="$attrs"
    :value="modelValue"
    :maxlength="maxlength"
    :disabled="disabled"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>
<script>
import inputStateMixin from '../inputStateMixin'

export default {
  name: 'AsomInputTextarea',
  inheritAttrs: false,
  mixins: [ inputStateMixin ],
  props: {
    modelValue: {
      type: undefined,
    },
    rows: {
      type: undefined,
    },
    maxlength: {
      type: Number,
      default: 1000,
    },
    showLength: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  emits: [ 'update:modelValue' ],
}
</script>
<style scoped>
.AsomInputTextarea {
  @apply block px-3 py-2 text-black placeholder-gray-400 transition duration-100 ease-in-out bg-white border rounded shadow-sm max-w-full w-full
}

.AsomInputTextarea--state-default {
  @apply border-gray-300 focus:border-blue-500 focus:ring-blue-500 focus:ring-2 focus:outline-none focus:ring-opacity-50
}

.AsomInputTextarea--state-error {
  @apply border-red-300 ring-red-500 bg-red-50 focus:border-red-500 focus:ring-red-500 focus:ring-2 focus:outline-none focus:ring-opacity-50
}

.AsomInputTextarea--is-disabled {
  @apply bg-gray-100;
}
</style>