<template>
  <ul class="AsomUploadFileList">
    <li
      v-for="(file, i) in files"
      :key="i"
      :class="['AsomUploadFileList__File', fileClass(file)]"
    >
      <p class="AsomUploadFileList__FileName">
        {{ file.fileName || file.name }}
      </p>
      <p v-if="file.size" class="AsomUploadFileList__FileSize">
        {{ formatSize(file.size) }}
      </p>
      <div v-if="!disabledRemove">
        <asom-icon
          v-if="file.state === 'progress'"
          icon="spinner"
          class="AsomUploadFileList__LoadingIcon"
        />
        <asom-icon
          v-else-if="file.state !== 'progress'&& isShowDeleteIcon"
    
          icon="close"
          class="AsomUploadFileList__RemoveIcon"
          @click="onRemove(file)"
        />
      </div>
      <div v-else class="flex flex-wrap content-start">
        <a
          v-if="showViewOption(file)"
          @click="viewAttachment(file)"
          class="flex flex-wrap content-center text-blue-600 hover:text-blue-500 cursor-pointer"
        >
          <asom-icon icon="eye" size="sm" />
        </a>
        <a
          v-if="!disabledDownload"
          @click="downloadAttachment(file)"
          class="download-btn flex flex-wrap content-center text-indigo-600 hover:text-indigo-500 cursor-pointer pl-4"
        >
          <asom-icon icon="software-download" />
        </a>
      </div>
    </li>
  </ul>
</template>

<script>
import get from "lodash.get";
import { downloadHandler } from "../../../services/serviceHandlers";
import { downloadFile } from "../../../services/file.service";
import AsomIcon from "../../icon/AsomIcon";

export default {
  name: "AsomUploadFileList",
  components: {
    AsomIcon,
  },
  props: {
    /**
     * List of files <br />
     * `{ fileId, fileName, size, type, progress, uploadCompleted }`
     */
    files: {
      type: Array,
    },
    disabledRemove: {
      type: Boolean,
      default: false,
    },
    disabledDownload: {
      type: Boolean,
      default: false,
    },
    isShowDeleteIcon:{
      type: Boolean,
      default: false,
    }
  },
  emits: ["remove"],
  methods: {
    fileClass({ state }) {
      return state === 'failed' ?
        "border-red-300 bg-red-100" :
        "border-gray-300 bg-white";
    },
    formatSize(size) {
      if (size < 1024) {
        return size + " B";
      } else if (size < 1024 * 1024) {
        return (size / 1024).toFixed() + " KB";
      } else {
        return (size / (1024 * 1024)).toFixed(2) + " MB";
      }
    },
    onRemove(file) {
      /**
       * When the delete button is clicked
       * @event remove
       * @type {object}
       */
      this.$emit("remove", file);
    },
    async downloadAttachment(attachment) {
      this.error = null;
      const response = await downloadHandler(
        get(attachment, "attachmentId", ""),
        get(attachment, "fileName", "")
      );
      if (!response.success) {
        this.error = response.message;
        this.$scrollTop();
      }
    },
    async viewAttachment(attachment) {
      this.error = null;
      let fileType = this.getFileType(get(attachment, "fileName", null));
      const response = await downloadFile({
        fileId: get(attachment, "attachmentId", null),
      });
      if (response) {
        var type = "";
        if (fileType == "pdf" || fileType == "PDF") {
          type = "application/pdf";
        } else if (fileType == "png" || fileType == "PNG") {
          type = "image/png";
        } else if (fileType == "jpg" || fileType == "JPG") {
          type = "image/jpg";
        } else if (fileType == "jpeg" || fileType == "JPEG") {
          type = "image/jpeg";
        }
        var file = new Blob([response.data], {
          type: type,
        });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } else {
        this.error = "Failed to open file";
        this.$scrollTop();
      }
    },
    showViewOption(attachment) {
      let fileType = this.getFileType(get(attachment, "fileName", null));
      return ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "pdf", "PDF"].includes(fileType);
    },
    getFileType(fileName) {
      if (fileName !== null) {
        let nameArr = fileName.split(".");
        return nameArr[nameArr.length - 1];
      }
    },
  },
};
</script>
<style scoped>
.AsomUploadFileList {
  @apply max-w-4xl space-y-4;
}

.AsomUploadFileList__File {
  @apply w-full flex space-x-4 justify-start px-6 py-2 border-2 rounded-md;
}

.AsomUploadFileList__FileName {
  @apply flex-1 font-bold;
}

.AsomUploadFileList__FileSize {
  @apply text-gray-400;
}

.AsomUploadFileList__LoadingIcon {
  @apply animate-spin -my-1;
}

.AsomUploadFileList__RemoveIcon {
  @apply text-gray-400 hover:text-black -my-1;
}
</style>
