<template>
  <div class="AsomTooltip">
    <div
      ref="toggle"
      class="AsomTooltip__Toggle"
      role="button"
      aria-describedby="tooltip"
      @mouseenter="showTooltip"
      @mouseleave="hideTooltip"
      @focus="showTooltip"
      @blur="hideTooltip"
    >
      <slot name="toggleText">
        <span v-if="toggleText" class="AsomTooltip__ToggleText">{{toggleText}}</span>
        <asom-icon v-else class="AsomTooltip__ToggleIcon" icon="info" size="sm" />
      </slot>
    </div>
    <div ref="popout"
      :class="['AsomTooltip__Popout', isShow && 'AsomTooltip__Popout--is-show']"
    >
      <div
        ref="tooltipContentWrapper"
        class="AsomTooltip__PopoutContentWrapper"
        @mouseenter="showTooltip"
        @mouseleave="hideTooltip"
        @scroll="onScroll"
      >
        <div ref="tooltipContent" class="contain">
          <!-- @slot Tooltip content -->
          <slot>
            <p class="asom-p">{{ tooltipText }}</p>
          </slot>
        </div>
      </div>
      <svg class="AsomTooltip__Arrow AsomTooltip__Arrow--bottom" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
      <svg class="AsomTooltip__Arrow AsomTooltip__Arrow--top" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,-127.5 255,0"/></svg>
      <svg class="AsomTooltip__Arrow AsomTooltip__Arrow--left" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
      <svg class="AsomTooltip__Arrow AsomTooltip__Arrow--right" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
      <!-- <div data-popper-arrow
        @mouseenter="showTooltip"
        @mouseleave="hideTooltip">
        <svg
          class="absolute text-black h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve">
          <polygon class="fill-current" points="0,0 127.5,127.5 255,0"/>
        </svg>
      </div> -->
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { createPopper } from '@popperjs/core'
import AsomIcon from '../icon/AsomIcon'

export default {
  name: "AsomTooltip",
  components: {
    AsomIcon,
  },
  props: {
    /**
     * Toggle text
     */
    toggleText: {
      type: String,
    },

    /**
     * Tooltip text
     */
    tooltipText: {
      type: String,
    },

    placement:{
      type: String,
      default: 'top',
    }
  },
  setup() {
    const toggle = ref(null)
    const popout = ref(null)
    const tooltipContent = ref(null)

    return {
      toggle,
      popout,
      tooltipContent,
    }
  },
  data() {
    return {
      isShow: false,
    }
  },
  methods: {
    showTooltip() {
      if (!this.isShow) {
        this.isShow = true
        this.updatePopper()
      }
    },

    hideTooltip() {
      this.isShow = false
    },
    initPopper() {
      const reference = this.toggle
      const popper = this.popout
      this.popper = createPopper(reference, popper, {
        placement: this.placement,
        strategy: 'fixed',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ],
      })
    },

    updatePopper() {
      this.$nextTick(() => {
        if (!this.popper) {
          this.initPopper()
        }
        this.popper.update()
      })
    },
  },
}
</script>
<style scoped>
.AsomTooltip {
  @apply inline-flex
}

.AsomTooltip__ToggleIcon {
  @apply text-blue-600
}

.AsomTooltip__PopoutContentWrapper {
  @apply bg-black text-white text-xs rounded py-1 px-4 right-0 bottom-full;
  max-width: 50vw;
}

.AsomTooltip__Popout {
  @apply fixed z-20 hidden
}

.AsomTooltip__Popout--is-show {
  @apply block
}


.AsomTooltip__Popout[data-popper-placement^='top'] .AsomTooltip__Arrow--bottom,
.AsomTooltip__Popout[data-popper-placement^='bottom'] .AsomTooltip__Arrow--top,
.AsomTooltip__Popout[data-popper-placement^='left'] .AsomTooltip__Arrow--right,
.AsomTooltip__Popout[data-popper-placement^='right'] .AsomTooltip__Arrow---left {
  @apply visible
}

.AsomTooltip__Arrow {
  @apply absolute text-black h-2 w-full left-0 top-full invisible
}
</style>