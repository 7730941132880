import {
  Html5Qrcode, 
  Html5QrcodeScannerState,
  Html5QrcodeSupportedFormats, 
} from "html5-qrcode/cjs/index"

export default {
  emits: ["open-scanner", "close-scanner", "detect", "process"],
  data() {
    return {
      showScanner: false,
      lastResult: null
    };
  },
  beforeUnmount() {
    this.closeScanner();
  },
  computed: {
    scannerId() {
      return "scanner";
    },
    alertMessages_cannotUseCamera() {
      return "The bar code scanner requires permission from user to be used"
    },
    formatsToSupport() {
      return  [
        Html5QrcodeSupportedFormats.QR_CODE,
        Html5QrcodeSupportedFormats.AZTEC,
        Html5QrcodeSupportedFormats.CODABAR,
        Html5QrcodeSupportedFormats.CODE_39,
        Html5QrcodeSupportedFormats.CODE_93,
        Html5QrcodeSupportedFormats.CODE_128,
        Html5QrcodeSupportedFormats.DATA_MATRIX,
        Html5QrcodeSupportedFormats.MAXICODE,
        Html5QrcodeSupportedFormats.ITF,
        Html5QrcodeSupportedFormats.EAN_13,
        Html5QrcodeSupportedFormats.EAN_8,
        Html5QrcodeSupportedFormats.PDF_417,
        Html5QrcodeSupportedFormats.RSS_14,
        Html5QrcodeSupportedFormats.RSS_EXPANDED,
        Html5QrcodeSupportedFormats.UPC_A,
        Html5QrcodeSupportedFormats.UPC_E,
        Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
      ];
    }
  },
  watch: {
    // 监听相机按钮开关
    showScanner(newValue) {
      const { createQrcodeScanner, destroyQrcodeScanner } = this;
      setTimeout(function() {
        newValue ? createQrcodeScanner() : destroyQrcodeScanner();
      }, 100);
    },
    lastResult(newValue, oldValue) {
      if (newValue !== null && newValue != oldValue) 
        this.$emit('detect', newValue);
    }
  },
  methods: {
    // 打开相机界面
    openScanner() {
      this.showScanner = true;
    },
    // 关闭相机界面
    async closeScanner() {
      if (this.scanner && this.scanner.getState && this.scanner.stop) {
        const scannerState = this.scanner.getState();
        if (scannerState == Html5QrcodeScannerState.SCANNING || 
          scannerState == Html5QrcodeScannerState.PAUSED)
          await this.scanner.stop();
          await this.scanner.clear();
          this.scanner = null;
      }
      this.showScanner = false;
      await this.closeExistingCameraUsager();
    },
    // 获取相机权限
    async requestPermission() {
      try {
        await navigator.mediaDevices?.getUserMedia({ audio: false, video: true });
        return true;
      } catch (e) {
        this.closeScanner();
        console.log('无法启动相机.');
        alert("Cannot start camera");
        return false;
      }
    },
    // 停止所有可能启动的相机
    async closeExistingCameraUsager() {
      if (navigator && navigator.mediaDevices) {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
        mediaStream.getTracks()?.forEach(track => track.stop());
      }
    },
    // 启动相机
    async createQrcodeScanner() {
      console.log('create')
      try {
        console.log('create 2 - 停止相机调用')
        await this.closeExistingCameraUsager();
        console.log('create 3 -- 获取相机权限')
        const canUseCamera = await this.requestPermission();
        if (!canUseCamera) {
          console.log('条形码扫描仪需要用户的许可才能使用.');
          alert(this.alertMessages_cannotUseCamera);
          return;
        }
        
        const deviceInfo = await this.getScannerDevice();
        const config = this.getScannerConfig(); 
        const { onScanSuccess } = this;

        this.scanner = new Html5Qrcode(
          this.scannerId,
          {
            verbose: false,
            useBarCodeDetectorIfSupported: true,
          }
        );
        console.info('scanner-----------',this.scanner)
        this.scanner.start(
          deviceInfo,
          // { facingMode: "environment" },
          config,
          onScanSuccess,
        )
      } catch (e) {
        this.closeScanner();
        // console.log("相机初始化失败");
        alert(JSON.stringify(e));
        //alert("Failed to start camera");
      }
    },
    // 关闭相机
    async destroyQrcodeScanner() {
      this.closeExistingCameraUsager();
      this.$emit("close-scanner");
    },
    // 获取窗口大小
    getDeviceMediaSize() {
      if (window) {
        const height = window.innerHeight
        const width = window.innerWidth
        const minWidth = Math.min(height, width)
        return {
          minWidth,
          width,
          height,
        }
      }
      return null;
    },
    async getScannerDevice() {
      const devices = await Html5Qrcode.getCameras();
      if (devices && devices.length) {
        var cameraId = devices[0].id;
        var backCameras = devices.filter(device => device.label && device.label.toLowerCase && device.label.toLowerCase().includes("back"));
        if (backCameras.length != 0) {
          console.log(backCameras)
          cameraId = backCameras[backCameras.length - 1].id;
        }
        return { deviceId: cameraId };
      }
      return { facingMode: "environment" }
    },
    // 返回相机配置参数
    getScannerConfig() {
      const { minWidth } = this.getDeviceMediaSize();
      const config = { 
        fps: 10, 
        qrbox: { width: minWidth * 0.8, height: minWidth * 0.5 },
      }
      return config;
    },
    async onScanSuccess(decodedText, decodedResult) {
      if (decodedText !== this.lastResult) {
        this.lastResult = decodedText;
        console.log(`Scan result ${decodedText}`, decodedResult);
      }
      this.closeScanner();
    }
  },
}