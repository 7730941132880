<template>
  <div class="AsomInputDate">
    <v-date-picker
      :mode="pickerMode"
      is24hr
      :min-date="minDate"
      :max-date="maxDate"
      :masks="masks"
      :popover="popover"
      :modelValue="modelValue"
      @update:modelValue="(val) => $emit('update:modelValue', val)"
    >
      <template v-slot="{ inputValue, inputEvents }" v-if="pickerMode !== 'time'">
        <asom-input-text
          :readonly="disabled"
          :disabled="disabled"
          :state="state"
          :modelValue="inputValue"
          v-on="inputEvents"
        />
      </template>
    </v-date-picker>
  </div>
</template>
<script>
import AsomInputText from "../text/AsomInputText";
import inputStateMixin from "../inputStateMixin";

export default {
  name: "AsomInputDateTime",
  components: {
    AsomInputText,
  },
  mixins: [inputStateMixin],
  props: {
    "modelValue": {
      type: undefined
    }, 
    "pickerMode": {
      type: String,
      default: 'datetime',
      validator: (value) => [ 'date', 'time', 'datetime' ].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    },
    datepickerPlacement: {
      type: String,
      default: 'left',
      validator: (val) => [
        'auto', 
        'top', 'top-start', 'top-end',
        'right', 'right-start', 'right-end',
        'bottom', 'bottom-start', 'bottom-end',
        'left', 'left-start', 'left-end', 
      ].includes(val)
    },
  },
  emits: ["update:modelValue"],
  // watch: {
  //   modelValue(newValue, oldValue) {
  //     if (this.pickerMode === 'time') {
  //     }
  //   }
  // },
  computed: {
    masks() {
      return {
        input: 'DD/MM/YYYY',
        inputDateTime24hr: 'DD/MM/YYYY HH:mm',
        inputTime24hr: 'HH:mm',
      }
    },
    popover() {
      return {
        visibility: this.disabled ? 'hidden' : 'click',  
        'placement': this.datepickerPlacement
      }
    }
  },
};
</script>
<style>
/** Time picker */
/* .AsomInputDate .vc-container {
  height:38px;
  min-height: 28px;
} */

.AsomInputDate .vc-popover-content-wrapper {
  @apply z-20
}

.AsomInputDate .vc-time-picker {
  padding: 2px 8px;
  padding-top: 3px;
}

.AsomInputDate .vc-time-icon {
  display: none;
}

.AsomInputDate .vc-time-content {
  margin-left: 0;
}

.AsomInputDate .vc-time-content .vc-time-date {
  display: none !important;
}
.AsomInputDate .vc-select select {
  background-image: none;
}
</style>
