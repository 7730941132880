<template>
  <div class="AsomUpload">
    <asom-button
      class="AsomUpload__Button"
      size="sm"
      icon="software-upload"
      outline
      :variant="state === 'error' ? 'error' : 'primary'"
      :text="uploadTitle"
      @click="handleClick"
    />
    <p class="AsomUpload__Description">{{ uploadDescription }}</p>
    <input
      class="AsomUpload__Input"
      type="file"
      ref="fileInput"
      :accept="acceptedFileTypes"
      multiple
      @change="onFileAdded"
    />
    <asom-upload-file-list
      class="AsomUpload__FileList"
      :files="allFiles"
      @remove="onFileRemoved"
      isShowDeleteIcon
    />
  </div>
</template>
<script>
import get from "lodash.get";
import uniqby from "lodash.uniqby";
import { nanoid } from "nanoid";
import { uploadFile } from "@/services/file.service";
import { UPLOAD_FILE } from "@/constants/apis";
import inputStateMixin from "../inputStateMixin";
import AsomUploadFileList from "./_AsomUploadFileList";
import { ATTACHMENT_CATEGORIES } from "../../../constants/APIEnums/attachmentEnums";

export default {
  name: "AsomUpload",
  mixins: [inputStateMixin],
  components: {
    AsomUploadFileList,
  },
  props: {
    uploadTitle: {
      type: String,
      default: "Upload file",
    },
    uploadDescription: {
      type: String,
      default: "Jpg, png or pdf only. Total file size must not exceed 20MB.",
    },
    /**
     *  Accepted file MIME types
     */
    acceptedFileTypes: {
      type: String,
      default: "image/jpeg,image/png,application/pdf",
    },
    url: {
      type: String,
      default: UPLOAD_FILE,
    },
    withCredentials: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Array,
      default: null,
    },
    maxFiles: {
      type: Number,
      default: 10,
    },
    // set to 0 for unlimited file size
    maxFileSizeInBytes: {
      type: Number,
      default: 20971520, // 20MB
    },
    maxFileSizeError: {
      type: String,
      default: "Cannot upload file exceeding 20MB.",
    },
    category: {
      type: Number,
      default: ATTACHMENT_CATEGORIES.OTHERS,
      validator: (val) => Object.values(ATTACHMENT_CATEGORIES).includes(val),
      required: true,
    },
    isOnline:{
      type: Boolean,
      default: true,
    }
  },
  emits: [
    "update:modelValue",
    "file-added",
    "file-removed",
    "file-uploading",
    "file-upload-completed",
    "file-upload-succeeded",
    "file-upload-failed",
    "file-upload-cancelled",
    "max-files-reached",
  ],
  data() {
    return {
      uploadingFiles: [],
      isUploading: false,
      hasError: false,
    };
  },
  computed: {
    allFiles() {
      return [...(this.modelValue || [])];
    },
  },
  methods: {
    handleClick() {
      this.$refs.fileInput.click();
    },
    onFileAdded(event) {
      let files = get(event, "target.files");
      if (!files) return;
      files = Array.from(files);
      const exceedSize =
        this.maxFileSizeInBytes > 0 &&
        files.some((file) => file.size > this.maxFileSizeInBytes);
      if (exceedSize) {
        alert(this.maxFileSizeError);
        files = files.filter((file) => file.size <= this.maxFileSizeInBytes);
        this.$refs.fileInput.value = null;
      }
      this.$refs.fileInput.modelValue = "";
      this.handleFileListUpdated(files);
    },
    handleFileListUpdated(files) {
      if (files.length <= 0) return;
      const validFileTypes = ["image/png", "image/jpeg", "application/pdf"];
      files.forEach((file) => {
        if(!validFileTypes.includes(file.type))
          alert(`Cannot upload file '${file.fileName}'. Please upload a valid file of type png/jpeg/pdf.`)
        if(this.isOnline){
          file.fileId = nanoid();
          file.fileName = file.name;
          file.state = "progress";
         
         
        }else{
          const reader = new FileReader()
          reader.onload = () => {
            const img = new Image()
            img.src = reader.result
            img.onload = () => {
              const canvas = document.createElement('canvas')
              canvas.width = img.width
              canvas.height = img.height
              const ctx = canvas.getContext('2d')
              ctx.drawImage(img, 0, 0)
              const base64data = canvas.toDataURL(file.type)
              file.base64data = base64data;
           
            }
          }
          reader.readAsDataURL(file)
          file.fileId = nanoid();
          file.state = 'completed';
          file.originalName = file.name;
          file.fileName = file.name;
        }
      
     
      });
       this.uploadingFiles = files.filter(file => validFileTypes.includes(file.type));
  
      if(this.isOnline){
       
        this.upload();
      }else{
        this.updateFiles( this.uploadingFiles,1);
      }
      
    },
    async upload() {
      const resp = await uploadFile(this.uploadingFiles, this.category);
      if (resp.success) {
        const fileList = get(resp, "payload.files", []);
        this.uploadingFiles = [];
        this.updateFiles(fileList);
      } else {
        this.uploadingFiles = this.uploadingFiles.map((file) => {
          file.state = "failed";
          return file;
        });
       
      }
    },
    updateFiles(fileList,type) {
      if(type == 1){
        let files = [...this.modelValue, ...fileList]
        this.$emit("update:modelValue", files);
      }else{
         let files = uniqby([
          ...this.modelValue,
          ...fileList.map((file) => (
           
            {
            ...file,
            state: "completed",
          })),
        ],
        "fileName"
      );
      this.$emit("update:modelValue", files);
      }
     
    },
    onFileRemoved(file) {
      this.$refs.fileInput.value = null;
      this.$emit("file-removed", file);
      const { fileId, state } = file;
      if (fileId) {
        if ((!state || state === "completed") && this.modelValue) {
          const newModelValue = this.modelValue.filter(
            (_file) => _file.fileId !== fileId
          );
          this.$emit("update:modelValue", newModelValue);
        } else {
          this.uploadingFiles = this.uploadingFiles.filter(
            (_file) => _file.fileId !== fileId
          );
        }
      } 
    },
  },
};
</script>
<style scoped>
.AsomUpload {
  @apply space-y-2;
}
.AsomUpload__Description {
  @apply text-sm;
}

.AsomUpload__Input {
  @apply hidden;
}

.AsomUpload__FileList {
  @apply mt-4;
}
</style>
